import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { blocks, canvas, currentBlock, storeCanvasData } from '../defs/store';
import { blocks as bs, colorSort } from '../defs/package';
import BlockBrick from './BlockBrick';
import { keyframes } from '@emotion/react';

const blockToSortNumber = (b: typeof bs[0]) =>
  (b.type === 'plate' ? 0 : 1000) + colorSort.findIndex((c) => c === b.color);

export default observer(PackageBrickSelection);
function PackageBrickSelection({
  inExchange,
  setInExchange,
}: {
  inExchange: boolean;
  setInExchange: (inExchange: boolean) => void;
}) {
  const [current, exChange] = React.useState<number | null>(null);
  React.useEffect(() => {
    if (!inExchange) exChange(null);
  }, [inExchange]);
  return (
    <div id="package-brick-selection" css={{ display: 'flex', flexWrap: 'wrap' }}>
      {Object.keys(blocks)
        .map(Number)
        .sort((blockA, blockB) => blockToSortNumber(bs[blockA]) - blockToSortNumber(bs[blockB]))
        .map((block) => {
          const selected = currentBlock.get() === block;
          const b = bs[block];
          return (
            <div
              key={block + '_' + blocks[block]}
              itemID={
                '' + (b.type === 'plate' ? 0 : 1000) + colorSort.findIndex((c) => c === b.color)
              }
              css={[
                {
                  width: 64,
                  flex: '1 1 auto',
                  margin: 0,
                  padding: 4,
                  display: 'inline-flex',
                },
                selected ? { boxShadow: 'inset 4px 4px #aaa, inset -4px -4px #aaa' } : {},
                current === block
                  ? {
                      animation: `${keyframes({
                        '0%': { boxShadow: 'inset 4px 4px #aaa, inset -4px -4px #aaa' },
                        '80%': { boxShadow: 'inset 4px 4px #00000000, inset -4px -4px #00000000' },
                      })} 0.5s ease infinite`,
                    }
                  : undefined,
              ]}
            >
              <BlockBrick
                onClick={() => {
                  if (inExchange) {
                    if (current) {
                      const _blocks: typeof blocks = {};
                      Object.keys(blocks)
                        .map(Number)
                        .forEach((blk) => (_blocks[blk] = blocks[blk]));
                      canvas
                        .filter((blk) => [block, current].includes(blk.get() || 0))
                        .forEach((blk) => {
                          if (block === blk.get()) {
                            blk.set(current);
                            _blocks[block] += 1;
                            _blocks[current] -= 1;
                          } else {
                            blk.set(block);
                            _blocks[block] -= 1;
                            _blocks[current] += 1;
                          }
                        });
                      Object.keys(blocks)
                        .map(Number)
                        .forEach((blk) => (blocks[blk] = _blocks[blk]));
                      storeCanvasData();
                      setInExchange(false);
                    } else {
                      exChange(block);
                    }
                  } else {
                    if (selected) {
                      currentBlock.set(null);
                    } else {
                      currentBlock.set(block);
                    }
                  }
                }}
                css={[
                  {
                    margin: '2px 0',
                  },
                ]}
                block={bs[block]}
              />
              <div css={{ height: 20, lineHeight: '20px' }}>{' × ' + blocks[block]}</div>
            </div>
          );
        })}
      {Array.from(Array(3)).map((_, i) => (
        <div
          key={i}
          css={{ width: 60, height: 0, margin: 0, padding: '0 6px', flex: '1 1 auto' }}
        />
      ))}
    </div>
  );
}
