import Canvas from './Canvas';
import BaseSizeChoice from './BaseSizeChoice';
import Modal from './Modal';
import { observable } from 'mobx';
import { observer } from 'mobx-react-lite';
import { canvas } from '../defs/store';
import Menu from './Menu';
import Palette from './Palette';

const modals = observable.box<[JSX.Element, () => void][]>(
  canvas.length === 0
    ? [
        [
          <BaseSizeChoice isFirstTime={true} onSubmit={() => modalAction.close()} />,
          () => undefined,
        ],
      ]
    : []
);
export const modalAction = {
  add: (component: JSX.Element, onClosed: () => void): void =>
    modals.set([...modals.get(), [component, onClosed]]),
  close: (): void => modals.set(modals.get().slice(0, -1)),
};

export default observer(Main);
function Main() {
  return (
    <div id="Main" css={{ height: '100%' }}>
      <Menu />
      <Canvas />
      <Palette />
      <canvas style={{ display: 'none' }} />
      {modals.get().map(([component, onClosed], i) => (
        <Modal key={i} onClosed={onClosed}>
          {component}
        </Modal>
      ))}
    </div>
  );
}
