import * as React from 'react';
import Zoom from '../svgs/zoom';
import ZoomIn from '../svgs/zoom-in';
import ZoomOut from '../svgs/zoom-out';
import Folder from '../svgs/folder';
import Image from '../svgs/image';
import Box from '../svgs/file';
import Setting from '../svgs/settings';
import { zoom } from './Canvas';
import { colors } from '../defs/package';
import { openImagePage } from '../defs/store';
import BaseSizeChoice from './BaseSizeChoice';
import { modalAction } from './Main';
import PictureLoader from './PictureLoader';

export default function Menu(): React.ReactElement {
  const [zoomOpened, setZoomOpened] = React.useState(false);
  const [folderOpened, setFolderOpened] = React.useState(false);
  return (
    <div
      id="Menu"
      css={{
        height: '100%',
        width: '40px',
        background: '#FFF',
        '& > div': {
          borderRadius: '6px',
          borderStyle: 'solid',
          borderColor: '#05131d',
          overflow: 'hidden',
          transition: '0.5s',
          transitionProperty: 'height',
          '& > svg': { padding: '5px 0' },
        },
        '& svg': { height: 30, width: 30, padding: 5, margin: 0 },
      }}
    >
      <Setting
        onClick={() =>
          modalAction.add(<BaseSizeChoice isFirstTime={false} onSubmit={modalAction.close} />, () =>
            location.reload()
          )
        }
      />
      <Zoom onClick={() => setZoomOpened(!zoomOpened)} />
      <div
        css={{
          height: zoomOpened ? 80 : 0,
          borderWidth: zoomOpened ? 3 : 0,
          padding: zoomOpened ? '0 0 5px' : '0 3px',
          margin: zoomOpened ? '0px 2px 5px' : '0px 2px',
          '& > svg:first-of-type': zoomOpened ? {} : { padding: '8px 0 5px' },
        }}
      >
        <ZoomOut onClick={() => zoom.set(Math.max(20, zoom.get() - 20))} />
        <ZoomIn onClick={() => zoom.set(Math.min(300, zoom.get() + 20))} />
      </div>
      <Folder onClick={() => setFolderOpened(!folderOpened)} />
      <div
        css={{
          height: folderOpened ? 80 : 0,
          borderWidth: folderOpened ? 3 : 0,
          padding: folderOpened ? '0 0 5px' : '0 3px',
          margin: folderOpened ? '0px 2px 5px' : '0px 2px',
          '& > svg:first-of-type': folderOpened ? {} : { padding: '8px 0 5px' },
        }}
      >
        <Box
          onClick={() => {
            const data = sessionStorage.getItem('canvas');
            modalAction.add(
              <div
                css={{
                  background: '#FFFFFF',
                  width: 300,
                  padding: 20,
                  display: 'flex',
                  flexFlow: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  '&>*': { width: '100%' },
                }}
                onClick={(e) => e.stopPropagation()}
              >
                <div>保存データ</div>
                <textarea id="data" value={data || ''} readOnly />
                <div
                  css={{
                    display: 'flex',
                    height: 50,
                    alignItems: 'center',
                    '&>div': { flexGrow: 1, margin: '5px 5px 0' },
                  }}
                >
                  <div
                    css={{
                      height: 30,
                      lineHeight: '30px',
                      borderRadius: 5,
                      backgroundColor: '#32CD32',
                      color: '#FFFFFF',
                      textAlign: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      const element = document.getElementById('data') as HTMLTextAreaElement;
                      if (!element) return;
                      element.select();
                      element.setSelectionRange(0, element.value.length);
                      document.execCommand('copy');
                    }}
                  >
                    Copy
                  </div>
                  <div
                    onClick={modalAction.close}
                    css={{
                      height: 30,
                      lineHeight: '30px',
                      borderRadius: 5,
                      backgroundColor: colors['Bright Light Blue'],
                      color: '#FFFFFF',
                      textAlign: 'center',
                      cursor: 'pointer',
                    }}
                  >
                    OK
                  </div>
                </div>
              </div>,
              modalAction.close
            );
          }}
        />
        <Image onClick={() => openImagePage()} />
      </div>
      <Image
        onClick={() =>
          modalAction.add(<PictureLoader onClose={modalAction.close} />, modalAction.close)
        }
      />
    </div>
  );
}
