export default ({ onClick }: { onClick: () => void }): React.ReactElement => (
  <svg
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    aria-labelledby="imageIconTitle"
    stroke="#05131d"
    strokeWidth="2.25"
    strokeLinecap="square"
    strokeLinejoin="miter"
    fill="none"
    color="#05131d"
    onClick={onClick}
    style={{ cursor: 'pointer' }}
  >
    <rect width="18" height="18" x="3" y="3" />
    <path strokeLinecap="round" d="M3 14l4-4 11 11" /> <circle cx="13.5" cy="7.5" r="2.5" />
    <path strokeLinecap="round" d="M13.5 16.5L21 9" />
  </svg>
);
