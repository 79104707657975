export default (): React.ReactElement => (
  <svg
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    aria-labelledby="arrowUpIconTitle"
    stroke="#a0a5a9"
    strokeWidth="2.25"
    strokeLinecap="square"
    strokeLinejoin="miter"
    fill="none"
    color="#a0a5a9"
  >
    <title id="arrowUpIconTitle">Arrow Up</title> <path d="M18 9l-6-6-6 6" /> <path d="M12 21V4" />
    <path strokeLinecap="round" d="M12 3v1" />
  </svg>
);
