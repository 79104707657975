export default ({
  children,
  onClosed,
}: {
  children: JSX.Element;
  onClosed: () => void;
}): React.ReactElement => {
  return (
    <div
      css={{
        position: 'fixed',
        left: 0,
        top: 0,
        width: '100vw',
        height: '100vh',
        background: 'rgba(0,0,0,0.3)',
        '&>div': {
          position: 'fixed',
          left: '50vw',
          top: '50vh',
          transform: 'translate(-50%, -50%)',
        },
      }}
      onClick={onClosed}
    >
      {children}
    </div>
  );
};
