import { colors } from '../defs/package';

export default (): React.ReactElement => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="40px"
    height="40px"
    viewBox="0 0 16 16"
    enableBackground="new 0 0 16 16"
    xmlSpace="preserve"
  >
    <rect x={0} y={0} width={16} height={16} fill={colors.Black} />
    {Array.from(Array(16)).map((_, i) =>
      Array.from(Array(16)).map((_, j) => (
        <circle key={i + ',' + j} cx={i + 0.5} cy={j + 0.5} r={0.2} fill="#555555" />
      ))
    )}
  </svg>
);
