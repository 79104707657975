export default ({
  onClick,
  inExchange,
}: {
  onClick: () => void;
  inExchange: boolean;
}): React.ReactElement => (
  <svg
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    aria-labelledby="retweetIconTitle"
    stroke="#05131d"
    strokeWidth="2.25"
    strokeLinecap="square"
    strokeLinejoin="miter"
    fill="none"
    color="#05131d"
    onClick={onClick}
    style={{ cursor: 'pointer', background: inExchange ? '#00000033' : undefined }}
  >
    <path d="M13 18L6 18L6 7" />
    <path d="M3 9L6 6L9 9" /> <path d="M11 6L18 6L18 17" /> <path d="M21 15L18 18L15 15" />
  </svg>
);
