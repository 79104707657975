import * as React from 'react';
import { packages, blocks, colors } from '../defs/package';
import {
  blocks as blocksCount,
  canvas,
  canvasSize,
  packages as packagesCount,
  storeCanvasData,
} from '../defs/store';

export default function PictureLoader({ onClose }: { onClose: () => void }): React.ReactElement {
  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [isError, setIsError] = React.useState(false);
  return (
    <div
      css={{
        width: 300,
        background: '#fff',
        padding: 15,
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <input
        type="file"
        accept="image/png, image/jpeg"
        ref={inputRef}
        css={{ display: 'block', margin: '15px auto' }}
        onChange={() => {
          if (!inputRef.current?.files || !inputRef.current?.files[0]) {
            setIsError(true);
            return;
          }
          setIsError(false);
        }}
      />
      <div
        onClick={() => {
          const ctx = canvasRef.current?.getContext('2d');
          if (!ctx) return;
          if (!inputRef.current?.files || !inputRef.current?.files[0]) {
            setIsError(true);
            return;
          }
          const file = inputRef.current?.files[0];
          setIsError(false);
          const reader = new FileReader();
          reader.onload = () => {
            const img = new Image();
            img.onload = () => {
              ctx.drawImage(img, 0, 0, canvasSize[0] * 16, canvasSize[1] * 16);
              const imgData = ctx.getImageData(0, 0, canvasSize[0] * 16, canvasSize[1] * 16);
              const data = imgData.data; // rgba、1バイト×4のデータ

              const _blocksCount: { [block: number]: number } = {};
              packages
                .filter((pkg) => packagesCount[pkg.name])
                .forEach((pkg) =>
                  pkg.blocks.forEach(
                    (blk) =>
                      (_blocksCount[blk.block] =
                        (_blocksCount[blk.block] || 0) + blk.n * packagesCount[pkg.name])
                  )
                );

              const patternCount = Math.pow(Object.keys(_blocksCount).length, 1 / 4);
              const blocksWithRGB = Object.keys(_blocksCount)
                .map(Number)
                .map((blk) => [blk, colors[blocks[blk].color]] as [number, string])
                .map(
                  (blk) =>
                    [
                      blk[0],
                      [
                        blk[1].substring(1, 3),
                        blk[1].substring(3, 5),
                        blk[1].substring(5, 7),
                      ].map((hex) => Number('0x' + hex)),
                    ] as [number, [number, number, number]]
                );
              const patternToBlock: { [pattern: number]: number } = {};

              // R,G,Bごとに差分をとって、合計したものが近いものを採用すればいいじゃない？
              for (let i = 0, len = data.length; i * 4 < len; i += 1) {
                const _i = i * 4;
                const r = data[_i],
                  g = data[_i + 1],
                  b = data[_i + 2];
                // a = data[_i + 3];
                const pattern = Math.floor(
                  (b / 255) * patternCount +
                    (g / 255) * patternCount * patternCount +
                    (r / 255) * patternCount * patternCount * patternCount
                );

                if (!patternToBlock[pattern]) {
                  let current: [number, number, number] | null = null;
                  let currentDiff = 0;
                  blocksWithRGB
                    .filter((blkRgb) => !Object.values(patternToBlock).includes(blkRgb[0]))
                    .forEach((blkRgb) => {
                      if (!current) {
                        patternToBlock[pattern] = blkRgb[0];
                        current = blkRgb[1];
                        currentDiff = current.reduce((sum, v, i) => sum + Math.abs(data[i] - v), 0);
                        return;
                      }
                      const next = blkRgb[1];
                      const nextDiff = next.reduce((sum, v, i) => sum + Math.abs(data[i] - v), 0);
                      if (nextDiff < currentDiff) patternToBlock[pattern] = blkRgb[0];
                    });
                }
                canvas[i].set(patternToBlock[pattern]);
                _blocksCount[patternToBlock[pattern]] -= 1;
              }
              Object.keys(blocksCount)
                .map(Number)
                .forEach((blk) => (blocksCount[blk] = _blocksCount[blk] || 0));

              storeCanvasData();
              onClose();
            };
            img.src = reader.result as string;
          };
          reader.readAsDataURL(file);
        }}
        css={{
          height: 30,
          lineHeight: '30px',
          borderRadius: 5,
          backgroundColor: isError ? 'red' : '#32CD32',
          color: '#FFFFFF',
          textAlign: 'center',
          cursor: 'pointer',
          width: 100,
          margin: '5px auto',
        }}
      >
        読み込み
      </div>
      <canvas ref={canvasRef} style={{ display: 'none' }} />
    </div>
  );
}
