import { keyframes } from '@emotion/react';
import * as React from 'react';
import PaletteBricks from './PaletteBricks';
import Circle from '../svgs/circle';
import Box from '../svgs/box-alt';
import Exchange from '../svgs/retweet';
import ArrowUp from '../svgs/arrow-up';
import { modalAction } from './Main';
import PackageSelection from './PackageSelection';
import { blocks, currentBlock } from '../defs/store';
import { observer } from 'mobx-react-lite';

export default observer(Palette);
function Palette() {
  const width = 250;
  const height = 410;
  const [xy, setXY] = React.useState<[number, number]>([
    document.body.clientWidth - width,
    document.body.clientHeight - height,
  ]);
  const [inExchange, setInExchange] = React.useState(false);
  return (
    <div
      css={{
        height,
        width,
        position: 'fixed',
        background: '#FFFFFFDD',
        '& svg': {
          width: 30,
          height: 30,
        },
      }}
      style={{
        left: xy[0],
        top: xy[1],
      }}
    >
      <div>
        <span>
          <Circle
            start={(e) => {
              if ((e as React.TouchEvent).touches !== undefined) {
                const touchmove = (ev: TouchEvent) =>
                  setXY([ev.touches[0].clientX - 15, ev.touches[0].clientY]);
                const touchend = () => {
                  document.removeEventListener('touchmove', touchmove);
                  document.removeEventListener('touchend', touchend);
                };
                document.addEventListener('touchmove', touchmove);
                document.addEventListener('touchend', touchend);
              } else {
                const mousemove = (ev: MouseEvent) => setXY([ev.clientX - 15, ev.clientY - 15]);
                const mouseup = () => {
                  document.removeEventListener('mousemove', mousemove);
                  document.removeEventListener('mouseup', mouseup);
                };
                document.addEventListener('mousemove', mousemove);
                document.addEventListener('mouseup', mouseup);
              }
            }}
          />
        </span>
        <span>
          <Box
            onClick={() => {
              modalAction.add(<PackageSelection />, modalAction.close);
            }}
          />
        </span>
        <span>
          <Exchange
            inExchange={inExchange}
            onClick={() => {
              currentBlock.set(null);
              setInExchange(!inExchange);
            }}
          />
        </span>
      </div>
      {Object.values(blocks).some((c) => c > 0) ? (
        <PaletteBricks inExchange={inExchange} setInExchange={setInExchange} />
      ) : (
        <div
          css={{
            position: 'absolute',
            left: 25,
            animation: `${keyframes({ '0%': { top: 50 }, '100%': { top: 25 } })} 1s ease infinite`,
            '&>svg': { width: 40, height: 40 },
          }}
        >
          <ArrowUp />
        </div>
      )}
    </div>
  );
}
