type Package = {
  name: 'Sith' | 'Iron Man' | 'Beatles' | 'Warhol';
  blocks: {
    block: number;
    n: number;
  }[];
};

type Blocks = {
  [code: number]: {
    color: keyof typeof colors;
    type: 'plate' | 'tile';
  };
};
export type Block = Blocks[0] | null;
export const emptyBlockBase = Array.from(Array(16 * 16)).map(() => null);

export const colors = {
  Black: '#05131d',
  'Pearl Dark Gray': '#575857',
  'Dark Stone Gray': '#6c6e68',
  'Medium Stone Gray': '#a0a5a9',
  White: '#ffffff',
  'Dark Brown': '#352100',
  'Reddish Brown': '#582a12',
  'Dark Tan': '#958a73',
  Tan: '#e4cd9e',
  'Dark Red': '#720e0f',
  Red: '#c91a09',
  'Medium Dark Flesh': '#cc702a',
  'Dark Pink': '#c870a0',
  'Bright Pink': '#e4adc8',
  Magenta: '#923978',
  'Dark Orange': '#a95500',
  Orange: '#fe8a18',
  'Bright Light Orange': '#f8bb3d',
  'Pearl Gold': '#aa7f2e',
  Yellow: '#f2cd37',
  'Bright Light Yellow': '#fff03a',
  'Dark Blue': '#0a3463',
  'Medium Azure': '#36aebf',
  'Sand Blue': '#6074a1',
  'Bright Light Blue': '#9fc3e9',
};
export const colorSort = [
  'Black',
  'Pearl Dark Gray',
  'Dark Stone Gray',
  'Medium Stone Gray',
  'White',
  'Dark Brown',
  'Reddish Brown',
  'Dark Tan',
  'Tan',
  'Dark Red',
  'Red',
  'Medium Dark Flesh',
  'Dark Pink',
  'Bright Pink',
  'Magenta',
  'Dark Orange',
  'Orange',
  'Bright Light Orange',
  'Pearl Gold',
  'Yellow',
  'Bright Light Yellow',
  'Dark Blue',
  'Medium Azure',
  'Sand Blue',
  'Bright Light Blue',
];
export const blockNumbers = [
  614101,
  614121,
  614126,
  4157103,
  4161734,
  4210633,
  4211525,
  4216581,
  4523159,
  6021623,
  6038201,
  6167641,
  6284070,
  6284071,
  6284572,
  6284573,
  6284577,
  6284582,
  6284584,
  6284586,
  6284587,
  6284589,
  6284596,
  6314247,
  6315780,
  6315781,
  6315782,
  6315783,
  6319958,
  6322813,
  6322816,
  6322819,
  6322821,
  6322822,
  6322823,
  6322840,
  6322841,
  6322842,
];
export const blocks: Blocks = {
  614101: { type: 'plate', color: 'White' },
  614121: { type: 'plate', color: 'Red' },
  614126: { type: 'plate', color: 'Black' },
  4157103: { type: 'plate', color: 'Orange' },
  4161734: { type: 'plate', color: 'Tan' },
  4210633: { type: 'plate', color: 'Dark Stone Gray' },
  4211525: { type: 'plate', color: 'Medium Stone Gray' },
  4216581: { type: 'plate', color: 'Reddish Brown' },
  4523159: { type: 'plate', color: 'Pearl Gold' },
  6021623: { type: 'plate', color: 'Dark Blue' },
  6038201: { type: 'plate', color: 'Pearl Dark Gray' },
  6167641: { type: 'plate', color: 'Medium Dark Flesh' },
  6284070: { type: 'tile', color: 'Black' },
  6284071: { type: 'tile', color: 'Medium Stone Gray' },
  6284572: { type: 'tile', color: 'White' },
  6284573: { type: 'tile', color: 'Tan' },
  6284577: { type: 'tile', color: 'Yellow' },
  6284582: { type: 'tile', color: 'Orange' },
  6284584: { type: 'tile', color: 'Dark Blue' },
  6284586: { type: 'tile', color: 'Reddish Brown' },
  6284587: { type: 'tile', color: 'Bright Pink' },
  6284589: { type: 'tile', color: 'Medium Dark Flesh' },
  6284596: { type: 'tile', color: 'Dark Stone Gray' },
  6314247: { type: 'plate', color: 'Dark Red' },
  6315780: { type: 'plate', color: 'Dark Brown' },
  6315781: { type: 'plate', color: 'Dark Tan' },
  6315782: { type: 'plate', color: 'Dark Orange' },
  6315783: { type: 'plate', color: 'Sand Blue' },
  6319958: { type: 'plate', color: 'Bright Light Yellow' },
  6322813: { type: 'tile', color: 'Dark Brown' },
  6322816: { type: 'tile', color: 'Magenta' },
  6322819: { type: 'tile', color: 'Medium Azure' },
  6322821: { type: 'tile', color: 'Dark Pink' },
  6322822: { type: 'tile', color: 'Bright Light Orange' },
  6322823: { type: 'tile', color: 'Bright Light Blue' },
  6322840: { type: 'tile', color: 'Dark Orange' },
  6322841: { type: 'tile', color: 'Dark Tan' },
  6322842: { type: 'tile', color: 'Sand Blue' },
};

export const packages: Package[] = [
  {
    name: 'Sith',
    blocks: [
      { block: 614126, n: 877 },
      { block: 4157103, n: 125 },
      { block: 6315783, n: 139 },
      { block: 6021623, n: 477 },
      { block: 6314247, n: 328 },
      { block: 4211525, n: 110 },
      { block: 4210633, n: 151 },
      { block: 6319958, n: 92 },
      { block: 6315780, n: 200 },
      { block: 6038201, n: 271 },
      { block: 614101, n: 187 },
      { block: 614121, n: 286 },
    ],
  },
  {
    name: 'Iron Man',
    blocks: [
      { block: 614126, n: 476 },
      { block: 4523159, n: 232 },
      { block: 6021623, n: 529 },
      { block: 6315782, n: 162 },
      { block: 6315783, n: 23 },
      { block: 6315781, n: 97 },
      { block: 6314247, n: 214 },
      { block: 4216581, n: 191 },
      { block: 4211525, n: 31 },
      { block: 4210633, n: 91 },
      { block: 6315780, n: 196 },
      { block: 6167641, n: 208 },
      { block: 614101, n: 61 },
      { block: 4161734, n: 155 },
      { block: 614121, n: 308 },
    ],
  },
  {
    name: 'Beatles',
    blocks: [
      { block: 6284070, n: 698 },
      { block: 6322823, n: 57 },
      { block: 6284573, n: 283 },
      { block: 6284596, n: 141 },
      { block: 6322840, n: 85 },
      { block: 6284582, n: 74 },
      { block: 6322842, n: 52 },
      { block: 6322841, n: 137 },
      { block: 6284584, n: 121 },
      { block: 6322822, n: 65 },
      { block: 6284586, n: 250 },
      { block: 6284071, n: 51 },
      { block: 6322813, n: 554 },
      { block: 6284589, n: 29 },
      { block: 6284572, n: 149 },
    ],
  },
  {
    name: 'Warhol',
    blocks: [
      { block: 6284577, n: 587 },
      { block: 6284070, n: 629 },
      { block: 6284596, n: 131 },
      { block: 6322816, n: 46 },
      { block: 6322821, n: 587 },
      { block: 6284587, n: 587 },
      { block: 6322819, n: 587 },
    ],
  },
];
