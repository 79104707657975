import { canvasSize } from '../defs/store';
import BlockBase from './BlockBase';
import { observable } from 'mobx';
import { observer } from 'mobx-react-lite';

export const zoom = observable.box(100);
export default observer(Canvas);
function Canvas() {
  const zoomValue = zoom.get();
  return (
    <div
      css={{
        width: 'calc(100vw - 40px)',
        height: '100%',
        position: 'fixed',
        left: 40,
        top: 0,
        overflow: 'scroll',
      }}
    >
      <div
        css={{
          transformOrigin: '0 0 0',
          transform: `scale(${zoomValue / 100}, ${zoomValue / 100})`,
          width: 20 * 16 * canvasSize[0],
          height: 20 * 16 * canvasSize[1],
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        {Array.from(Array(canvasSize[1])).map((_, y) =>
          Array.from(Array(canvasSize[0])).map((_, x) => (
            <BlockBase n={y * canvasSize[0] + x} key={y * canvasSize[0] + x} />
          ))
        )}
      </div>
    </div>
  );
}
