import { observable, IObservableValue } from 'mobx';
import { blockNumbers, colors, blocks as blockDef, packages as packageDef } from './package';

export const currentBlock = observable.box<number | null>(null);
export const canvasSize = observable<[number, number]>([1, 1]);
export const canvas: IObservableValue<number | null>[] = [];
export const packages = observable({ Sith: 0, 'Iron Man': 0, Beatles: 0, Warhol: 0 });
export const blocks = observable<{ [block: number]: number }>({});
export const baseStr = '012345689abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

let w: Worker | undefined = undefined;
export const storeCanvasData = (): void => {
  if (w) w.terminate();
  w = new Worker('dist/worker.js');
  w.onmessage = (ev) => {
    sessionStorage.setItem('canvas', ev.data);
  };
  w.postMessage({
    canvasSize: [...canvasSize],
    packages: { ...packages, 'observable-object': undefined },
    canvas: canvas.map((c) => c.get()),
  });
};

export const setCanvasFromStr = (str: string): void => {
  canvas.splice(0, canvas.length);
  for (const bn of blockNumbers) {
    delete blocks[bn];
  }
  const splitIndex = str.indexOf(':');
  [canvasSize[0], canvasSize[1]] = str
    .substring(0, splitIndex)
    .split(',')
    .map((n) => Number(n));
  const splitIndex2 = str.indexOf(':', splitIndex + 1);
  [packages.Sith, packages['Iron Man'], packages.Beatles, packages.Warhol] = str
    .substring(splitIndex + 1, splitIndex2)
    .split(',')
    .map((n) => Number(n));

  const _blocks: typeof blocks = {};
  for (const pkg of packageDef) {
    const p = packages[pkg.name];
    if (p === 0) continue;
    pkg.blocks.forEach((pkgB) => (_blocks[pkgB.block] = (_blocks[pkgB.block] || 0) + pkgB.n * p));
  }
  for (let pos = splitIndex2 + 1; pos < str.length; pos += 1) {
    const chr = str.charAt(pos);
    if (chr === '_') {
      canvas.push(observable.box(null));
      continue;
    }
    const block = blockNumbers[baseStr.indexOf(chr)];
    canvas.push(observable.box(block));
    _blocks[block] = (_blocks[block] || 0) - 1;
  }
  Object.keys(blocks)
    .map(Number)
    .forEach((blk) => {
      blocks[blk] = _blocks[blk] || 0;
      delete _blocks[blk];
    });
  Object.keys(_blocks)
    .map(Number)
    .forEach((blk) => (blocks[blk] = _blocks[blk]));
};

const sessionData = sessionStorage.getItem('canvas');
if (sessionData) {
  setCanvasFromStr(sessionData);
}

export const openImagePage = (): void => {
  const c = document.getElementsByTagName('canvas')[0];
  const zoom = 2;
  c.width = canvasSize[0] * 16 * zoom;
  c.height = canvasSize[1] * 16 * zoom;
  const ctx = c.getContext('2d');
  if (!ctx) return;
  canvas
    .map((c) => c.get())
    .forEach((b, i) => {
      const rgb = colors[blockDef[b || 6284070].color];
      ctx.fillStyle = rgb;
      const x = i % (canvasSize[0] * 16);
      const y = Math.floor(i / (canvasSize[0] * 16));
      ctx.fillRect(x * zoom, y * zoom, zoom, zoom);
    });
  const link = document.createElement('a');
  link.download = 'image.png';
  link.href = c.toDataURL();
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
