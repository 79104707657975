import * as React from 'react';
import { canvas, canvasSize, storeCanvasData } from '../defs/store';
import BlockBase from '../svgs/block-base';
import Minus from '../svgs/minus';
import Plus from '../svgs/plus';
import { observer } from 'mobx-react-lite';
import { colors } from '../defs/package';
import { modalAction } from './Main';
import { observable } from 'mobx';

export default observer(BaseSizeChoice);
function BaseSizeChoice({ onSubmit, isFirstTime }: { onSubmit: () => void; isFirstTime: boolean }) {
  return (
    <div
      css={{
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#FFF',
        padding: 15,
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <div>ベースの数を選択してください。</div>
      <div>一つのパッケージには、9つのベースが入っています。</div>
      <div>変更できないので慎重に</div>
      <div>
        あとバグ報告は
        <a href="https://twitter.com/yiozio" target="_blank">
          @yiozio
        </a>
        に
      </div>
      <div css={{ position: 'relative', margin: '35px 5px 40px' }}>
        {Array.from(Array(canvasSize[1])).map((_, y) => (
          <div key={y} css={{ height: 40, marginBottom: 1 }}>
            {Array.from(Array(canvasSize[0])).map((__, x) => (
              <span key={x} css={{ margin: 0, marginRight: 1, padding: 0 }}>
                <BlockBase />
              </span>
            ))}
          </div>
        ))}
        {canvasSize[0] > 1 && (
          <div
            style={{
              position: 'absolute',
              width: 30,
              height: 30,
              left: -30,
              top: (41 * canvasSize[1]) / 2 - 15,
            }}
          >
            <Minus
              onClick={() => {
                const clone = canvas.splice(0, canvas.length);
                const canvasWidth = 16 * canvasSize[0];
                clone.forEach((brick, i) => {
                  const x = i % canvasWidth;
                  if (x >= canvasWidth - 16) return;
                  canvas.push(brick);
                });
                canvasSize[0] -= 1;
              }}
            />
          </div>
        )}
        {canvasSize[1] > 1 && (
          <div
            style={{
              position: 'absolute',
              width: 30,
              height: 30,
              left: (41 * canvasSize[0]) / 2 - 15,
              top: -30,
            }}
          >
            <Minus
              onClick={() => {
                canvasSize[1] -= 1;
                const count = canvasSize[0] * 16 * canvasSize[1] * 16;
                canvas.splice(count);
              }}
            />
          </div>
        )}
        <div
          style={{
            position: 'absolute',
            width: 30,
            height: 30,
            left: 41 * canvasSize[0],
            top: (41 * canvasSize[1]) / 2 - 15,
          }}
        >
          <Plus
            onClick={() => {
              const clone = canvas.splice(0, canvas.length);
              const canvasWidth = 16 * canvasSize[0];
              clone.forEach((brick, i) => {
                if (i > 0 && i % canvasWidth === 0) {
                  canvas.push(...Array.from(Array(16)).map(() => observable.box(null)));
                }
                canvas.push(brick);
                // 横方向の追加削除の場合のブロック調整;
                // 縦方向の削除の場合のブロック削除;
              });
              canvasSize[0] += 1;
            }}
          />
        </div>
        <div
          style={{
            position: 'absolute',
            width: 30,
            height: 30,
            left: (41 * canvasSize[0]) / 2 - 15,
            top: 41 * canvasSize[1],
          }}
        >
          <Plus onClick={() => (canvasSize[1] += 1)} />
        </div>
      </div>
      <div>
        {canvasSize[0] * 128}mm × {canvasSize[1] * 128}mm（外枠をつける場合、上下左右に+8mmずつ）
      </div>
      <div>要パッケージ数: {Math.ceil((canvasSize[0] * canvasSize[1]) / 9)}</div>
      <div
        css={{
          display: 'flex',
          height: 50,
          width: 300,
          alignItems: 'center',
          '&>div': { flexGrow: 1, margin: '5px 5px 0' },
        }}
      >
        <div
          css={{
            height: 30,
            lineHeight: '30px',
            borderRadius: 5,
            backgroundColor: '#32CD32',
            color: '#FFFFFF',
            textAlign: 'center',
            cursor: 'pointer',
          }}
          onClick={() => {
            modalAction.add(<Modal />, modalAction.close);
          }}
        >
          読み込み
        </div>
        {!isFirstTime && (
          <div
            onClick={() => location.reload()}
            css={{
              height: 30,
              lineHeight: '30px',
              borderRadius: 5,
              backgroundColor: colors['Bright Light Blue'],
              color: '#FFFFFF',
              textAlign: 'center',
              cursor: 'pointer',
            }}
          >
            キャンセル
          </div>
        )}
        <div
          onClick={() => {
            storeCanvasData();
            onSubmit();
          }}
          css={{
            height: 30,
            lineHeight: '30px',
            borderRadius: 5,
            backgroundColor: colors['Bright Light Blue'],
            color: '#FFFFFF',
            textAlign: 'center',
            cursor: 'pointer',
          }}
        >
          OK!
        </div>
      </div>
    </div>
  );
}

const Modal = () => {
  const [text, setText] = React.useState('');
  return (
    <div
      css={{
        background: '#FFFFFF',
        width: 300,
        padding: 20,
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        '&>*': { width: '100%' },
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <div>保存データ</div>
      <textarea id="data" value={text} onChange={(e) => setText(e.target.value)} />
      <div
        css={{
          display: 'flex',
          height: 50,
          alignItems: 'center',
          '&>div': { flexGrow: 1, margin: '5px 5px 0' },
        }}
      >
        <div
          onClick={() => {
            sessionStorage.setItem('canvas', text);
            location.reload();
          }}
          css={{
            height: 30,
            lineHeight: '30px',
            borderRadius: 5,
            backgroundColor: colors['Bright Light Blue'],
            color: '#FFFFFF',
            textAlign: 'center',
            cursor: 'pointer',
          }}
        >
          OK
        </div>
      </div>
    </div>
  );
};
