export default ({ onClick }: { onClick: () => void }): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="boxAltIconTitle"
    stroke="#05131d"
    strokeWidth="2.25"
    strokeLinecap="square"
    strokeLinejoin="miter"
    color="#05131d"
    onClick={onClick}
    style={{ cursor: 'pointer' }}
  >
    <rect x="5" y="9" width="14" height="11" />
    <rect width="16" height="4" transform="matrix(1 0 0 -1 4 9)" />
    <rect x="9" y="12" width="6" height="3" />
  </svg>
);
