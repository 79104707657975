export default ({ onClick }: { onClick: () => void }): React.ReactElement => (
  <svg
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    aria-labelledby="fileIconTitle"
    stroke="#05131d"
    strokeWidth="2.25"
    strokeLinecap="square"
    strokeLinejoin="miter"
    fill="none"
    color="#05131d"
    onClick={onClick}
    style={{ cursor: 'pointer' }}
  >
    <path strokeLinecap="round" d="M13 3v6h6" />
    <path d="M13 3l6 6v12H5V3z" />
  </svg>
);
