import { observer } from 'mobx-react-lite';
import { packages, blocks } from '../defs/store';
import { packages as packageDef, blocks as blockDef, colors } from '../defs/package';
import Minus from '../svgs/minus';
import Plus from '../svgs/plus';

const packNameToUrl = {
  Sith: 'https://amzn.to/2Sq0n8D',
  'Iron Man': 'https://amzn.to/2GB6C76',
  Warhol: 'https://amzn.to/2GhfT4x',
  Beatles: 'https://amzn.to/2GEipRP',
};

export default function PackageSelection(): React.ReactElement {
  return (
    <div
      id="package-selection"
      css={{
        width: 252,
        display: 'flex',
        textAlign: 'center',
        flexWrap: 'wrap',
        background: '#FFF',
        padding: 15,
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <Package packName="Sith" />
      <Package packName="Iron Man" />
      <Package packName="Beatles" />
      <Package packName="Warhol" />
    </div>
  );
}

const Package = observer(_Package);
function _Package({ packName }: { packName: 'Sith' | 'Iron Man' | 'Beatles' | 'Warhol' }) {
  const blocks =
    packageDef.find((pkgDef) => pkgDef.name === packName)?.blocks.sort((a, b) => b.n - a.n) || [];
  const sum = blocks.reduce((sum, { n }) => sum + n, 0);
  return (
    <div>
      <a
        target="_blank"
        href={packNameToUrl[packName]}
        css={{ height: 20, lineHeight: '20px', margin: '0 3px' }}
      >
        {packName}
      </a>
      <div
        css={{
          width: 114,
          height: 114,
          margin: '0 6px',
          boxShadow: '0 0 5px 0 #000',
          position: 'relative',
          overflow: 'hidden',
          display: 'flex',
          flexWrap: 'wrap',
          background: '#333',
        }}
      >
        {blocks.map(({ block, n }) => {
          const rate = Math.round((n / sum) * 16 * 16);
          return Array.from(Array(rate)).map((_, i) => (
            <div
              key={block + '_' + i}
              css={{
                flex: '0 0 auto',
                margin: 0,
                padding: 0,
                width: '6.25%',
                height: '6.25%',
                borderRadius: '50%',
                background: colors[blockDef[block].color],
              }}
            />
          ));
        })}
      </div>
      <div
        css={{
          width: '100%',
          height: 20,
          lineHeight: '20px',
          marginBottom: 10,
          textAlign: 'center',
          position: 'relative',
          '& svg': { width: 14, height: 14, padding: 3 },
        }}
      >
        <div
          css={{
            position: 'absolute',
            top: 0,
            left: 10,
          }}
        >
          <Minus
            onClick={() => {
              addPackage(packName, -1);
            }}
          />
        </div>
        <div>{'× ' + packages[packName]}</div>
        <div
          css={{
            position: 'absolute',
            top: 0,
            right: 10,
          }}
        >
          <Plus
            onClick={() => {
              addPackage(packName, +1);
            }}
          />
        </div>
      </div>
    </div>
  );
}

function addPackage(packName: 'Sith' | 'Iron Man' | 'Beatles' | 'Warhol', additionNumber: number) {
  packages[packName] += additionNumber;
  const p = packageDef.find((pack) => pack.name === packName);
  p?.blocks.forEach((b) => {
    if (!blocks[b.block]) blocks[b.block] = 0;
    blocks[b.block] += b.n * additionNumber;
  });
}
