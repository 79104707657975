export default ({ onClick }: { onClick: () => void }): React.ReactElement => (
  <svg
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    aria-labelledby="zoomInIconTitle"
    stroke="#05131d"
    strokeWidth="2.25"
    strokeLinecap="square"
    strokeLinejoin="miter"
    fill="none"
    color="#05131d"
    onClick={onClick}
    style={{ cursor: 'pointer' }}
  >
    <path d="M14,16 C10.6862915,16 8,13.3137085 8,10 C8,6.6862915 10.6862915,4 14,4 C17.3137085,4 20,6.6862915 20,10 C20,13.3137085 17.3137085,16 14,16 Z" />
    <path d="M4 20L9.58788778 14.4121122" />
  </svg>
);
