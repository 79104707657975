import { canvas, storeCanvasData, currentBlock, blocks as blocksStore } from '../defs/store';
import { blocks } from '../defs/package';
import BlockBrick from './BlockBrick';
import { observer } from 'mobx-react-lite';
import { observable } from 'mobx';

export default observer(BlockBrickSync);
function BlockBrickSync({ index }: { index: number }) {
  if (canvas[index] === undefined) {
    canvas[index] = observable.box<number | null>(null);
  }
  const target = canvas[index].get();
  return (
    <BlockBrick
      onClick={() => {
        let block = currentBlock.get();
        if (block === target) {
          if (target === null) return;
          block = null;
        }
        const b = (block === null ? target : currentBlock.get()) as number;
        canvas[index].set(block);
        blocksStore[b] += block === null ? +1 : -1;

        storeCanvasData();
      }}
      block={blocks[target as number] || null}
    />
  );
}
