export default ({
  start,
}: {
  start: (e: React.MouseEvent | React.TouchEvent) => void;
}): React.ReactElement => (
  <svg
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    aria-labelledby="circleIconTitle"
    stroke="#05131d"
    strokeWidth="2.25"
    strokeLinecap="square"
    strokeLinejoin="miter"
    fill="none"
    color="#05131d"
    onTouchStart={start}
    onMouseDown={start}
    style={{ cursor: 'pointer' }}
  >
    <title id="circleIconTitle">Circle</title> <circle cx="12" cy="12" r="8" />
  </svg>
);
