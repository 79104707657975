import { colors } from '../defs/package';
import BlockBrickSync from './BlockBrickSync';
import { canvasSize } from '../defs/store';

export default function BlockBase({ n }: { n: number }): React.ReactElement {
  const nx = n % canvasSize[0];
  const ny = Math.floor(n / canvasSize[0]);
  return (
    <div
      css={{
        width: 20 * 16,
        height: 20 * 16,
        backgroundColor: colors.Black,
        display: 'flex',
        flexWrap: 'wrap',
      }}
    >
      {Array.from(Array(16)).map((_, y) =>
        Array.from(Array(16)).map((_, x) => {
          const index = ny * 16 * canvasSize[0] * 16 + y * canvasSize[0] * 16 + nx * 16 + x;
          return <BlockBrickSync key={index} index={index} />;
        })
      )}
    </div>
  );
}
