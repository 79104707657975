import { Theme } from '@emotion/react';
import { Interpolation } from '@emotion/serialize';
import { Block, colors } from '../defs/package';

export default function BlockBrick({
  block,
  onClick,
  css = {},
}: {
  block: Block;
  onClick?: () => void;
  css?: Interpolation<Theme>;
}): React.ReactElement {
  return (
    <div
      css={[
        { width: 20, height: 20, borderRadius: 10, position: 'relative' },
        block !== null
          ? {
              backgroundColor: colors[block?.color],
              boxShadow: 'inset 2px 2px rgba(255,255,255,.2)',
            }
          : {},
        onClick ? { cursor: 'pointer' } : {},
        css,
      ]}
      onClick={onClick}
    >
      {(!block || block.type === 'plate') && (
        <div
          css={{
            position: 'absolute',
            left: 5,
            top: 5,
            width: 10,
            height: 10,
            borderRadius: 5,
            boxShadow: '2px 2px 2px rgba(0,0,0,.2), inset 2px 2px rgba(255,255,255,.2)',
          }}
        />
      )}
    </div>
  );
}
